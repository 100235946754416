<template>
  <div class="wrapper login-wrapper">
    <div
      class="container-wide flex-col"
      :class="
        $store.state.auth.loggedIn &&
        !$store.state.userData.user.is_email_verified
          ? 'mt-12'
          : ''
      "
    >
      <div
        class="flex flex-col w-7/12 mobile:w-full mx-auto mt-12 register-container p-12 mobile:p-6 mb-8 relative"
        v-if="!$store.state.auth.status.loggedIn"
      >
        <h1 class="self-center mb-8 mobile:text-center">Registreeri konto</h1>
        <registration-form
          @navigateBack="resetState"
          :preset-email="presetEmail"
        ></registration-form>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationForm from "@/components/registration/RegistrationForm";

export default {
  data() {
    return {
      presetEmail: ""
    };
  },
  components: {
    RegistrationForm
  },
  methods: {
    handleTypeSelection(e) {
      this.asProvider = e;
      this.typeSelected = true;
    },
    resetState() {
      this.typeSelected = false;
      this.asProvider = false;
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    if (this.$route.query.e) {
      this.presetEmail = this.$route.query.e;
    }
  }
};
</script>

<style lang="scss">
.register-wrapper {
  background-image: url("/jumbo-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.register-container {
  @apply bg-white rounded-xl shadow-lg;
  --bg-opacity: 97%;
}
.register-cancel-button {
  position: absolute;
  top: 9%;
  left: 6%;
}
</style>
